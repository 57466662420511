import LeaderbordPlayer from "./components/LeaderboardPlayer";

import style from "./style/leaderboardPlayer.module.css";

import WavyText from "animations/WavyText";

import { Leaderboard } from "types/FetchedData";

export interface Props {
	podium: Leaderboard["podium"];
	position: Leaderboard["position"] | null;
}

const LeaderboardPodium: React.FC<Props> = ({ podium, position }) => {
	return (
		<div className={style.page}>
			<WavyText text="Leaderboard" className={style.title} delay={0.5} />
			<div>
				{podium.map((data, index) => {
					return (
						<LeaderbordPlayer
							key={index}
							position={data.position}
							name={data.name}
							experience={data.experience}
							containerStyle={style.podiumContainer}
							profilePicture={data.profilePicture}
						/>
					);
				})}
			</div>
			{position !== null && position.length !== 0 && (
				<>
					<div className={style.line} />
					{position.map((data, index) => {
						return (
							<LeaderbordPlayer
								key={index}
								position={data.position}
								name={data.name}
								experience={data.experience}
								containerStyle={style.positionContainer}
								profilePicture={data.profilePicture}
							/>
						);
					})}
				</>
			)}
		</div>
	);
};

export default LeaderboardPodium;
