import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { gamificationApi } from "./services/gamificationApi";

import userReducer from "./services/userReducer";

export const store = configureStore({
	reducer: {
		user: userReducer,
		[gamificationApi.reducerPath]: gamificationApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(gamificationApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
