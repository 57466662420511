import useFetchHomeData from "hooks/api/useFetchHomeData";
import Loading from "features/global/loading";
import HomePageContent from "./HomePageContent";
import HompePageErrorContent from "./HomePageErrorContent";
import useHourBackground from "hooks/home/useHourBackground";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const HomePage = () => {
	const homepageData = useFetchHomeData();
	const background = useHourBackground();
	if (homepageData.error) {
		const homePageError = homepageData.error as FetchBaseQueryError;
		const homePageErrorMessage = homePageError.data as string;
		return (
			<div>
				<HompePageErrorContent error={homePageErrorMessage} />
			</div>
		);
	}
	return (
		<div>
			{homepageData.isSuccess && homepageData.data && background != null ? (
				<HomePageContent
					usersTeam={homepageData.data.usersGlobal.filter((x) => x.teamId === homepageData.data?.user.teamId)}
					usersGlobal={homepageData.data.usersGlobal}
					user={homepageData.data.user}
					eventList={homepageData.data.events}
					background={background}
				/>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default HomePage;
