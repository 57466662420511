interface Props {
	mountain: "global" | "functionnal";
	setMountain: React.Dispatch<React.SetStateAction<"global" | "functionnal">>;
}

export const changeMountain = ({ mountain, setMountain }: Props) => {
	if (mountain === "global") {
		setMountain("functionnal");
	} else if (mountain === "functionnal") {
		setMountain("global");
	}
};
