import style from "../style/leaderboardPlayer.module.css";

import { Avatar } from "@chakra-ui/react";

import GoldMedal from "assets/images/medals/medal_gold.png";
import SilverMedal from "assets/images/medals/medal_silver.png";
import BronzeMedal from "assets/images/medals/medal_bronze.png";

interface Props {
	position: number | string;
	name: string;
	experience: number;
	containerStyle: string;
	profilePicture?: string;
}

const Color: { [key: string]: string } = {
	"1": "rgba(212, 175, 55, 1)",
	"2": "rgba(169, 176, 180, 1)",
	"3": "rgba(160, 113, 85, 1)",
	default: "rgba(255, 255, 255, 1)",
};

const Badge: { [key: string]: string } = {
	"1": GoldMedal,
	"2": SilverMedal,
	"3": BronzeMedal,
	default: "",
};

const LeaderbordPlayer: React.FC<Props> = ({ position, name, experience, containerStyle, profilePicture }) => {
	const borderColor = Color[position] ? Color[position] : Color["default"];
	let positionContent;

	if (Badge[position]) {
		positionContent = <img src={Badge[position]} className={style.center} alt={`${position}`} height={50} />;
	} else {
		positionContent = (
			<div style={{ display: "inline-block" }} className={style.center}>
				{position}
				<sup style={{ verticalAlign: "sub" }}>e</sup>
			</div>
		);
	}

	return (
		<div className={containerStyle} style={{ borderColor: borderColor }}>
			<Avatar
				name={name}
				size={"lg"}
				className={style.profilePicture}
				style={{ borderColor: Color[`${position}`] }}
				src={profilePicture ? `data:image/jpeg;base64,${profilePicture}` : ""}
			/>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					width: "100%",
				}}
			>
				<div className={style.gridItem}>{name}</div>
				<div className={style.gridItemCenter} style={{ width: "100%", alignSelf: "Center" }}>
					{positionContent}
				</div>
				<div className={style.experience} style={{ textAlign: "right" }}>
					{experience} m
				</div>
			</div>
		</div>
	);
};

export default LeaderbordPlayer;
