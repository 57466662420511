import style from "../style/mountain.module.css";

import FadeIn from "animations/FadeIn";

import UserPin from "../components/userPin";

import Events from "features/home/events/Events";

import { HomepageData } from "types/FetchedData";
import { LeaderboardPosition } from "types/NestedInterfaces";
import { Text } from "@chakra-ui/react";
import React from "react";

interface Props {
	usersTeam: LeaderboardPosition[];
	eventList: HomepageData["events"];
}

const FunctionnalMountain: React.FC<Props> = ({ usersTeam, eventList }) => {
	const groupedUsers = groupUsersByExperience(usersTeam);

	// Group user by experience group for better UI.
	function groupUsersByExperience(users: LeaderboardPosition[]): Record<string, LeaderboardPosition[]> {
		const groups: Record<string, LeaderboardPosition[]> = {};
		users.forEach((user) => {
			// We group users by their experience rounded to a round of 20.
			const expKey = Math.floor(user.experience / 20) * 20;
			const key = expKey.toString();
			if (!groups[key]) {
				// If group doesn't arleady exists, it's created.
				groups[key] = [];
			}
			// Then we push the user in the group.
			groups[key].push(user);
		});

		// Order alphabeticly inside groups
		for (const key in groups) {
			groups[key].sort((a, b) => a.name.localeCompare(b.name));
		}

		return groups;
	}

	// Create UI Tooltip for groups, if the user is alone in the group it'ill only be him, otherwise it'll be every user separated by "<br/>".
	const createTooltipContent = (users: LeaderboardPosition[]): JSX.Element => {
		const maxUsers = 10;
		const showEllipsis = users.length > maxUsers;
		return (
			<Text>
				{users.slice(0, maxUsers).map((u, index) => (
					<React.Fragment key={index}>
						<span
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<span style={{ textAlign: "left" }}>{u.name}</span>
							<span style={{ textAlign: "right" }}>{u.experience}m</span>
						</span>

						{index < users.length - 1 ? <br /> : null}
					</React.Fragment>
				))}
				{showEllipsis && <React.Fragment>{users.length - maxUsers} de plus ...</React.Fragment>}
			</Text>
		);
	};

	return (
		<FadeIn delay={0.3}>
			<div className={style.container} id={style.functionnalMountain}>
				{Object.entries(groupedUsers)
					.reverse()
					.map(([exp, users]) => (
						<UserPin
							key={exp}
							name={users.length > 1 ? `+ ${users.length}` : users[0].name}
							height={`${((parseInt(exp) % 1200) / 1200) * 100}%`}
							tooltipText={createTooltipContent(users)}
							profilePicture={users.length > 1 ? `` : users[0].profilePicture}
						/>
					))}
			</div>
			{eventList.length > 0 ? <Events eventList={eventList} /> : <></>}
		</FadeIn>
	);
};

export default FunctionnalMountain;
