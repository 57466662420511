import { HomepageData, Challenge } from "types/FetchedData";
import { Box, Button, Heading, Image, Center, Tooltip } from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/card";
import { AiOutlineSearch } from "react-icons/ai";

import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
} from "@chakra-ui/react";

interface Props {
	user: HomepageData["user"];
	challenge: Challenge;
}

const ChallengeComponent: React.FC<Props> = ({ challenge }) => {
	var altText = "Obtained on : " + new Date(challenge.dateClaimed).toLocaleDateString("fr-FR");
	var imageSrc = "https://i.ibb.co/RSWrHyD/trophee.png";
	if (challenge.imageUrl) {
		imageSrc = challenge.imageUrl;
	}
	return (
		<div>
			<Card marginLeft={{ base: "-20%" }} marginBottom={{ base: "20px" }}>
				<CardHeader>
					<Center position="relative">
						<Image
							borderRadius="lg"
							bgColor="gray.100"
							boxSize={{ base: "100%", md: "100%" }}
							maxWidth={150}
							maxHeight={150}
							src={imageSrc}
							opacity={challenge.isClaimed ? 1 : 0.2}
						/>
						{challenge.isClaimed ? (
							<Box
								position="absolute"
								right="10%"
								top="5%"
								boxSize={{ base: "30%", sm: "30%", md: "30%" }}
							>
								<Tooltip label={altText} aria-label="A tooltip">
									<Image
										borderRadius="full"
										boxSize="100%"
										width="30%"
										height="100%"
										src="https://i.ibb.co/CPGHL7Q/icons8-verified-badge-96.png"
									/>
								</Tooltip>
							</Box>
						) : (
							<></>
						)}
					</Center>
				</CardHeader>
				<CardBody>
					<Heading
						style={{
							fontSize: 15,
							textAlign: "center",
							marginTop: 15,
							marginBottom: 15,
						}}
					>
						{challenge.name}
					</Heading>
				</CardBody>
				<CardFooter display="flex" justifyContent="center">
					{DetailsPopover(challenge)}
				</CardFooter>
			</Card>
		</div>
	);
};

function DetailsPopover(challenge: Challenge) {
	var altText = "Obtained on : " + new Date(challenge.dateClaimed).toLocaleDateString("fr-FR");
	return (
		<Popover placement="bottom" closeOnBlur={true}>
			<PopoverTrigger>
				<Button aria-label="Search database">
					<AiOutlineSearch style={{ marginInlineEnd: "3px" }} /> Details
				</Button>
			</PopoverTrigger>
			<PopoverContent color="white" bg="blue.800" borderColor="blue.800">
				<PopoverHeader pt={4} fontWeight="bold" border="0" style={{ textAlign: "center" }}>
					{challenge.name}
				</PopoverHeader>
				<PopoverArrow bg="blue.800" />
				<PopoverCloseButton />
				<PopoverBody>
					<p style={{ paddingBottom: "10px" }}>
						{challenge.description ? <>{challenge.description}</> : "No description."}
					</p>
					<hr />
					<p style={{ paddingTop: "10px" }}>{challenge.reward ? <>{challenge.reward}</> : "No reward."}</p>
				</PopoverBody>
				{challenge.isClaimed ? (
					<PopoverFooter border="0" display="flex" alignItems="center" justifyContent="space-between" pb={4}>
						<Box fontSize="sm">{altText}</Box>
					</PopoverFooter>
				) : (
					<></>
				)}
			</PopoverContent>
		</Popover>
	);
}

export default ChallengeComponent;
