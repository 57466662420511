import { motion } from "framer-motion";

import { GiMountainClimbing } from "react-icons/gi";

import { useNavigate } from "react-router-dom";

import style from "./style/leaderboardButton.module.css";

const LeaderboardButton = () => {
	const navigate = useNavigate();

	return (
		<motion.button
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			className={style.container}
			onClick={() => navigate("/leaderboard")}
		>
			<GiMountainClimbing size={40} />
		</motion.button>
	);
};

export default LeaderboardButton;
