import { useState, useRef } from "react";

import { HomepageData } from "types/FetchedData";

import UserProfile from "features/global/userProfile";
import UserExperience from "features/global/userExperience";
import GlobalMountain from "features/home/mountains/GlobalMountain";
import FunctionnalMountain from "features/home/mountains/FunctionnalMountain";
import MountainSwitcher from "features/home/mountainSwitcher/MountainSwitcher";
import LogoutButton from "features/global/logoutButton";
import HelpButton from "features/home/helpButton";
import LeaderboardButton from "features/home/leaderboardButton/LeaderboardButton";
import Clouds from "features/home/clouds";

import style from "./style/HomePage.module.css";

import FadeIn from "animations/FadeIn";

import { Button, Textarea } from "@chakra-ui/react";

import { gradients } from "utils/backgroundGradients";
import { LeaderboardPosition } from "types/NestedInterfaces";
import BugButton from "features/home/bugButton";
import GenericModal from "components/GenericModal";
import { useReportMutation } from "stores/services/gamificationApi";

interface Props {
	user: HomepageData["user"];
	usersTeam: LeaderboardPosition[];
	usersGlobal: HomepageData["usersGlobal"];
	eventList: HomepageData["events"];
	background: string;
}

const HomePageContent: React.FC<Props> = ({ user, usersTeam, usersGlobal, eventList, background }) => {
	const [mountain, setMountain] = useState<"global" | "functionnal">("global");

	// Modal
	const [isModalOpen, setModalOpen] = useState(false);
	const [report, { isLoading }] = useReportMutation();
	const messageInputRef = useRef<HTMLTextAreaElement>(null);

	const handleOpenModal = () => setModalOpen(true);
	const handleCloseModal = () => setModalOpen(false);

	const handleReport = async (e: React.FormEvent) => {
		e.preventDefault();
		const message = messageInputRef.current?.value;
		if (message && message.trim() !== "") {
			await report(message);
			handleCloseModal();
		}
	};

	return (
		<div className={style.HomePage} style={{ background: gradients[`${background}`] }}>
			<FadeIn delay={0.5}>
				<UserProfile name={user.name} department={user.function} />
				<UserExperience experience={user.experience} level={user.level} globalProgress={user.globalProgress} />
				<Clouds />
			</FadeIn>
			{mountain === "global" ? (
				<GlobalMountain usersGlobal={usersGlobal} eventList={eventList} />
			) : (
				<FunctionnalMountain usersTeam={usersTeam} eventList={eventList} />
			)}
			<FadeIn delay={0.6}>
				{user.teamId != null ? (
					<MountainSwitcher mountain={mountain} setMountain={setMountain} isDisabled={false} />
				) : (
					<MountainSwitcher mountain={mountain} setMountain={setMountain} isDisabled={true} />
				)}
			</FadeIn>
			<FadeIn delay={0.8}>
				<LeaderboardButton />
			</FadeIn>
			<FadeIn delay={0.8}>
				<HelpButton />
			</FadeIn>
			<FadeIn delay={0.8}>
				<div>
					<BugButton onOpenModal={handleOpenModal} />
					<GenericModal
						isOpen={isModalOpen}
						onClose={handleCloseModal}
						header={<div>Report a bug</div>}
						body={<Textarea placeholder="Describe the bug..." ref={messageInputRef} />}
						button={
							<Button colorScheme="blue" mr={3} onClick={handleReport} isLoading={isLoading}>
								Submit
							</Button>
						}
					/>
				</div>
			</FadeIn>
			<FadeIn delay={1}>
				<LogoutButton />
			</FadeIn>
		</div>
	);
};

export default HomePageContent;
