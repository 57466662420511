export interface Task {
	id: string;
	name: string;
	description: string;
	picture: string;
	isClaimable: boolean;
	difficulty: "Green" | "Blue" | "Red" | "Black";
	reward: number;
	typeTask?: string;
	isMicrosoft: boolean;
	isValid: boolean;
	timesClaimed: number;
	maxClaims: number;
	periodicity: Periodicity;
}

export interface EventSign {
	name: string;
	description: string;
	date: string;
}

export interface CurrentUser {
	userID: string;
	name: string;
	tasks: number;
	teamId: string;
	function: string;
	experience: number;
	globalProgress: number;
	lastTaskAll: number;
	lastTaskFun: number;
	level: number;
	success: number;
}

export interface LeaderboardPosition {
	position: string;
	name: string;
	teamId: string;
	experience: number;
	profilePicture: string;
}

export interface LeaderboardPodium {
	position: string;
	name: string;
	experience: number;
	profilePicture: string;
}

export enum Periodicity {
	Null = "Null",
	Casual = "Casual",
	Weekly = "Weekly",
	Monthly = "Monthly",
	BiAnnual = "BiAnnual",
	Annual = "Annual",
}
