import { Tabs, TabList, Tab } from "@chakra-ui/react";

import TaskListTabs from "./components/TaskListTabs";

import style from "./style/TaskList.module.css";

import { HomepageData } from "types/FetchedData";
import { TasksPageTasklists } from "types/FetchedData";

interface Props {
	user: HomepageData["user"];
	taskList: TasksPageTasklists;
}

const TaskList: React.FC<Props> = ({ user, taskList }) => {
	return (
		<Tabs isFitted={true} variant="soft-rounded" colorScheme="blackAlpha">
			<TabList mb="1em" className={style.TaskList}>
				<Tab>
					<h1 className={style.TabTitle}>Global</h1>
				</Tab>
				<Tab>
					<h1 className={style.TabTitle}>Department</h1>
				</Tab>
				<Tab>
					<h1 className={style.TabTitle}>Certifications</h1>
				</Tab>
				<Tab>
					<h1 className={style.TabTitle}>Events</h1>
				</Tab>
			</TabList>
			<TaskListTabs
				user={user}
				globalTaskList={taskList.globalTaskList}
				functionalTaskList={taskList.functionalTaskList}
				certificationsTaskList={taskList.certificationsTaskList}
				eventsTaskList={taskList.eventsTaskList}
			/>
		</Tabs>
	);
};

export default TaskList;
