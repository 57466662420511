import { Dispatch, SetStateAction } from "react";

interface Props {
	hour: number;
	setBackground: Dispatch<SetStateAction<string | null>>;
}

const backgroundChoice = ({ hour, setBackground }: Props) => {
	if (hour >= 0 && hour <= 6) setBackground("night-background");
	else if (hour > 6 && hour <= 8) setBackground("morning-background");
	else if (hour > 8 && hour <= 10) setBackground("second-morning-background");
	else if (hour > 10 && hour <= 12) setBackground("third-morning-background");
	else if (hour > 12 && hour <= 14) setBackground("afternoon-background");
	else if (hour > 14 && hour <= 16) setBackground("second-afternoon-background");
	else if (hour > 16 && hour <= 18) setBackground("third-afternoon-background");
	else if (hour > 18 && hour <= 20) setBackground("pre-night-background");
	else if (hour > 20 && hour <= 24) setBackground("night-background");
};

export default backgroundChoice;
