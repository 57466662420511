import Loading from "features/global/loading/Loading";

import useFetchChallengeList from "hooks/api/useFetchChallengeList";
import useHourBackground from "hooks/home/useHourBackground";
import useFetchHomeData from "hooks/api/useFetchHomeData";

import ChallengePageContent from "./ChallengePageContent";

const ChallengePage = () => {
	const challengeData = useFetchChallengeList();
	const background = useHourBackground();
	const userData = useFetchHomeData();
	return (
		<div style={{ height: "100%" }}>
			{challengeData.isSuccess && challengeData.data && background && userData.data?.user != null ? (
				<ChallengePageContent
					background={background}
					challengeList={challengeData.data}
					user={userData.data?.user}
				/>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default ChallengePage;
