import "./VersionInfo.css";
import useFetchVersion from "hooks/api/useFetchVersion";
import { FaCodeBranch } from "react-icons/fa";

const VersionInfo = () => {
	const version = useFetchVersion();
	return (
		<div>
			{version.isSuccess ? (
				<span className="version-info">
					<FaCodeBranch style={{ marginRight: 3 }} /> {version.data}
				</span>
			) : (
				<></>
			)}
		</div>
	);
};
export default VersionInfo;
