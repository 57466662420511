import { Routes, Route } from "react-router-dom";

import ConnectionPage from "../pages/ConnectionPage";
import HomePage from "../pages/HomePage";
import Leaderboard from "pages/Leaderboard";
import TasksPage from "pages/TasksPage";

import useRequestAccessToken from "hooks/msal/useRequestAccessToken";

import { useAppSelector } from "stores/hooks";

import { selectIsAuthenticating } from "stores/services/userReducer";

import Loading from "features/global/loading";
import ChallengePage from "pages/ChallengePage/ChallengePage";

const IsAuthenticated: React.FC = () => {
	useRequestAccessToken();
	const isAuthenticating = useAppSelector(selectIsAuthenticating);

	return isAuthenticating ? (
		<Loading />
	) : (
		<Routes>
			<Route path="/home" element={<HomePage />} />
			<Route path="/leaderboard" element={<Leaderboard />} />
			<Route path="/tasks" element={<TasksPage />} />
			<Route path="/challenges" element={<ChallengePage />} />
			<Route path="*" element={<ConnectionPage />} />
		</Routes>
	);
};

export default IsAuthenticated;
