import { useEffect } from "react";

import { loginRequest } from "config/msalConfig";
import { useMsal } from "@azure/msal-react";

import { useAppDispatch } from "stores/hooks";
import { login } from "stores/services/userReducer";

import { useNavigate } from "react-router-dom";

const useRequestAccessToken = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { instance, accounts } = useMsal();

	useEffect(() => {
		const request = { ...loginRequest, account: accounts[0] };

		instance
			.acquireTokenSilent(request)
			.then((response) => {
				dispatch(
					login({
						token: response.accessToken,
						email: response.account?.username!,
					})
				);
			})
			.catch((error) => {});
	}, [instance, accounts, navigate, dispatch]);
};

export default useRequestAccessToken;
