import { HomepageData } from "types/FetchedData";
import { Challenge } from "types/FetchedData";
import ChallengeListcontent from "./components/ChallengeListContent";

interface Props {
	user: HomepageData["user"];
	challengeList: Challenge[];
}

const ChallengeListComponent: React.FC<Props> = ({ user, challengeList }) => {
	return <ChallengeListcontent user={user} challengeList={challengeList} />;
};

export default ChallengeListComponent;
