import { useState } from "react";

import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button } from "@chakra-ui/react";

import { HiChevronDown } from "react-icons/hi";

import { TasksPageTasklists } from "types/FetchedData";

import { filterLogic } from "./utils/filterLogic";
import { getFilters } from "./utils/getFilters";

interface Props {
	globalTaskList: TasksPageTasklists["globalTaskList"];
	setFilteredTaskList: React.Dispatch<React.SetStateAction<TasksPageTasklists["globalTaskList"]>>;
}

const TaskFilter: React.FC<Props> = ({ globalTaskList, setFilteredTaskList }) => {
	const [menuTitle, setMenuTitle] = useState("Category");
	const filter = getFilters({ globalTaskList });

	return (
		<Menu>
			<MenuButton
				transition="all 0.2s"
				colorScheme="whiteAlpha"
				style={{ color: "#4b4b4b" }}
				as={Button}
				rightIcon={<HiChevronDown size={25} style={{ marginTop: 4, marginRight: -7 }} />}
			>
				{menuTitle}
			</MenuButton>
			<MenuList>
				<MenuItem
					onClick={() => {
						setMenuTitle("Category");
						filterLogic({
							globalTaskList,
							setFilteredTaskList,
							filter: "all",
						});
					}}
				>
					All tasks
				</MenuItem>
				<MenuDivider />
				{filter.sort().map((data, index) => {
					return (
						<MenuItem
							key={index}
							onClick={() => {
								setMenuTitle(data!);
								filterLogic({
									globalTaskList,
									setFilteredTaskList,
									filter: data!,
								});
							}}
						>
							{data}
						</MenuItem>
					);
				})}
			</MenuList>
		</Menu>
	);
};

export default TaskFilter;
