import { UseToastOptions } from "@chakra-ui/react";

export const successToast: UseToastOptions = {
	title: "Success",
	position: "bottom-left",
	description: "A ping has been sent to a team leader",
	status: "success",
	duration: 9000,
	isClosable: true,
};

export const errorToast: UseToastOptions = {
	title: "Error",
	position: "bottom-left",
	description: "Please try again later or contact an administrator.",
	status: "error",
	duration: 9000,
	isClosable: true,
};

export const errorClaimToast: UseToastOptions = {
	title: "Error",
	position: "bottom-left",
	description: "You have already claimed this task.",
	status: "error",
	duration: 9000,
	isClosable: true,
};
