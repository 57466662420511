import FadeIn from "animations/FadeIn";

import { HomepageData, Challenge } from "types/FetchedData";

import { gradients } from "utils/backgroundGradients";

import UserProfile from "features/global/userProfile";
import HomeButton from "features/global/homeButton";

import style from "./Style/ChallengePage.module.css";
import ChallengeListComponent from "features/challenge/challengeList";

interface Props {
	user: HomepageData["user"];
	challengeList: Challenge[];
	background: string;
}

const ChallengePageContent: React.FC<Props> = ({ user, challengeList, background }) => {
	return (
		<div className={style.ProfilePage} style={{ background: gradients[`${background}`] }}>
			<FadeIn delay={0.3}>
				<UserProfile name={user.name} department={user.function} />
			</FadeIn>
			<FadeIn delay={0.4} className={style.TaskList}>
				<ChallengeListComponent challengeList={challengeList} user={user} />
			</FadeIn>
			<FadeIn delay={0.5}>
				<HomeButton />
			</FadeIn>
		</div>
	);
};

export default ChallengePageContent;
