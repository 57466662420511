import { IPublicClientApplication } from "@azure/msal-browser";

import { NavigateFunction } from "react-router";

interface Props {
	instance: IPublicClientApplication;
	navigate: NavigateFunction;
}

const msalLogout = async ({ instance, navigate }: Props) => {
	await instance
		.logoutRedirect()
		.then((response) => {
			navigate("/", { replace: true });
		})
		.catch((error) => {
			console.log("User canceled logout");
		});
};

export default msalLogout;
