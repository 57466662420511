import { Progress } from "@chakra-ui/react";

interface Props {
	globalProgress: number;
}

const ProgressBar: React.FC<Props> = ({ globalProgress }) => {
	return (
		<Progress
			size="md"
			value={globalProgress}
			colorScheme={"userProgress"}
			isAnimated={true}
			hasStripe={true}
			style={{ borderRadius: 20, marginTop: 10 }}
		/>
	);
};

export default ProgressBar;
