import { FC } from "react";

import { HTMLMotionProps } from "framer-motion";

import { motion } from "framer-motion";

interface Props extends HTMLMotionProps<"div"> {
	children: JSX.Element | JSX.Element[];
	delay?: number;
}

const FadeIn: FC<Props> = ({ children, delay = 0, ...props }) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ delay: delay, duration: 0.5 }}
			{...props}
		>
			{children}
		</motion.div>
	);
};

export default FadeIn;
