import { useState } from "react";

import { TabPanels, TabPanel } from "@chakra-ui/react";

import TaskListContent from "../TaskListContent";

import { HomepageData } from "types/FetchedData";
import { TasksPageTasklists } from "types/FetchedData";

import TaskFilter from "../TaskFilter";

interface Props {
	user: HomepageData["user"];
	globalTaskList: TasksPageTasklists["globalTaskList"];
	functionalTaskList: TasksPageTasklists["functionalTaskList"];
	certificationsTaskList: TasksPageTasklists["certificationsTaskList"];
	eventsTaskList: TasksPageTasklists["eventsTaskList"];
}

const TaskListTabs: React.FC<Props> = ({
	user,
	globalTaskList,
	functionalTaskList,
	certificationsTaskList,
	eventsTaskList,
}) => {
	const [filteredTaskList, setFilteredTaskList] = useState(globalTaskList);

	return (
		<TabPanels>
			<TabPanel>
				<TaskFilter globalTaskList={globalTaskList} setFilteredTaskList={setFilteredTaskList} />
				<TaskListContent user={user} taskList={filteredTaskList} margin={30} />
			</TabPanel>
			<TabPanel>
				<TaskListContent user={user} taskList={functionalTaskList} />
			</TabPanel>
			<TabPanel>
				<TaskListContent user={user} taskList={certificationsTaskList} />
			</TabPanel>
			<TabPanel>
				<TaskListContent user={user} taskList={eventsTaskList} />
			</TabPanel>
		</TabPanels>
	);
};

export default TaskListTabs;
