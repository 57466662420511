import style from "./style/mountainSwitcher.module.css";

import { Switch } from "@chakra-ui/react";

import { changeMountain } from "./utils/changeMountain";

interface Props {
	mountain: "global" | "functionnal";
	setMountain: React.Dispatch<React.SetStateAction<"global" | "functionnal">>;
	isDisabled: boolean;
}

const MountainSwitcher: React.FC<Props> = ({ mountain, setMountain, isDisabled }) => {
	return (
		<div className={style.container}>
			<div className={style.elements}>
				<h1 className={style.text}>Global</h1>
				<Switch
					size={"lg"}
					colorScheme={"other"}
					className={style.switch}
					onChange={() => changeMountain({ mountain, setMountain })}
					disabled={isDisabled}
				/>
				<h1 className={style.text}>Department</h1>
			</div>
		</div>
	);
};

export default MountainSwitcher;
