import style from "./style/events.module.css";

import { useDisclosure } from "@chakra-ui/react";

import { HomepageData } from "types/FetchedData";

import { motion } from "framer-motion";

import EventsModal from "./components/EventsModal";

interface Props {
	eventList: HomepageData["events"];
}

const Events: React.FC<Props> = ({ eventList }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<motion.div
				className={style.sign}
				style={{ transformOrigin: "bottom center" }}
				whileHover={{ transform: "rotate(15deg)" }}
				onClick={() => onOpen()}
			></motion.div>
			<EventsModal isOpen={isOpen} onClose={onClose} eventList={eventList} />
		</>
	);
};

export default Events;
