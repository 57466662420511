import { useMsal } from "@azure/msal-react";

import styles from "./style/Connection.module.css";

import AinosLogo from "assets/images/logo_ainos.png";
import msButton from "assets/images/ms_button.svg";

import { useNavigate } from "react-router-dom";
import MsalConnection from "./utils/MsalConnection";

const Connection = () => {
	const { instance } = useMsal();
	const navigate = useNavigate();

	return (
		<div className={styles.container}>
			<img className={styles.ainosLogo} src={AinosLogo} alt="logo Ainos" />
			<button
				className={styles.microsoftButton}
				onClick={() => {
					MsalConnection(instance, navigate);
				}}
			>
				<img src={msButton} alt="Sign in with Microsoft button" />
			</button>
		</div>
	);
};

export default Connection;
