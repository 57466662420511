import { loginRequest } from "config/msalConfig";

import { IPublicClientApplication } from "@azure/msal-browser";
import { NavigateFunction } from "react-router-dom";

const MsalConnection = async (instance: IPublicClientApplication, navigate: NavigateFunction) => {
	navigate("/home", { replace: true });

	await instance.loginRedirect(loginRequest).catch((error) => {
		console.log("User canceled authentification");
	});
};

export default MsalConnection;
