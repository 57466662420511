import { useState, useEffect } from "react";

import backgroundChoice from "./utils/backgroundChoice";

const useHourBackground = () => {
	const [background, setBackground] = useState<string | null>(null);

	useEffect(() => {
		const date = new Date();
		let hour = date.getHours();

		backgroundChoice({ hour, setBackground });
	}, []);

	return background;
};

export default useHourBackground;
