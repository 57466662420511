import { motion } from "framer-motion";

import { Tooltip, Popover, PopoverTrigger } from "@chakra-ui/react";

import TaskPopover from "./components/TaskPopover";

import style from "./style/TaskListContent.module.css";

import { HomepageData } from "types/FetchedData";

import Lottie from "lottie-react";
import animationData from "assets/images/lotties/pendingConfirmation.json";

import { badgePicture, certificationBadge } from "./utils/badgesConverter";
import { Task } from "types/NestedInterfaces";

interface Props {
	user: HomepageData["user"];
	task: Task;
}
const TaskComponent: React.FC<Props> = ({ user, task }) => {
	const badge = task.isMicrosoft ? certificationBadge[task.difficulty] : badgePicture[task.difficulty];
	return (
		<Popover>
			<Tooltip placement="top" label={task.name}>
				<div style={{ position: "relative" }}>
					<PopoverTrigger>
						<motion.div
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.9 }}
							className={style.TaskListContent}
							style={{
								cursor: "pointer",
							}}
						>
							<motion.div
								className={style.Badge}
								style={{
									backgroundImage: `url(${badge})`,
									opacity: task.timesClaimed > 0 ? 1 : 0.2,
									cursor: "pointer",
								}}
							/>
							{task.isValid === false && task.timesClaimed > 0 && (
								<div className={style.pending}>
									<Lottie
										animationData={animationData}
										loop={true}
										autoplay={true}
										style={{ width: 70, height: 70 }}
									/>
								</div>
							)}
							<p style={{ fontSize: 13, marginTop: 2 }}>{task.name.substring(0, 15)}...</p>
						</motion.div>
					</PopoverTrigger>
				</div>
			</Tooltip>
			<TaskPopover task={task} user={user} />
		</Popover>
	);
};

export default TaskComponent;
