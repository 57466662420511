import { PulseLoader } from "react-spinners";
import style from "./style/loading.module.css";
import useHourBackground from "hooks/home/useHourBackground";
import { gradients } from "utils/backgroundGradients";

const Loading = () => {
	const background = useHourBackground();
	return (
		<div className={style.container} style={{ background: gradients[`${background}`] }}>
			<div>
				<PulseLoader color={"#27ae60"} size={20} />
			</div>
		</div>
	);
};

export default Loading;
