import GreenBadge from "assets/images/badges/global/green.png";
import BlueBadge from "assets/images/badges/global/blue.png";
import RedBadge from "assets/images/badges/global/red.png";
import BlackBadge from "assets/images/badges/global/black.png";

import FundamentalsBadge from "assets/images/badges/Microsoft/fundamentalBadge.svg";
import AssociateBadge from "assets/images/badges/Microsoft/associateBadge.svg";
import ExpertBadge from "assets/images/badges/Microsoft/expertBadge.svg";

export const badgePicture = {
	Green: GreenBadge,
	Blue: BlueBadge,
	Red: RedBadge,
	Black: BlackBadge,
};

export const certificationBadge = {
	Green: FundamentalsBadge,
	Blue: AssociateBadge,
	Red: ExpertBadge,
	Black: ExpertBadge,
};
