//  Msal
import { MsalProvider } from "@azure/msal-react";

//  ChakraUI
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./config/chakraUI";

//  Redux
import { Provider } from "react-redux";
import { store } from "./stores/store";

// Router
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";

import VersionInfo from "components/VersionInfo/VersionInfo";

import { root, msalInstance } from "./config/startupConstants";

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<ChakraProvider theme={theme}>
					<Router />
					<VersionInfo />
				</ChakraProvider>
			</MsalProvider>
		</Provider>
	</BrowserRouter>
);
