import style from "./style/UserExperience.module.css";

import { motion } from "framer-motion";

import ProgressBar from "./components/ProgressBar";

import { useNavigate } from "react-router-dom";

interface Props {
	level: number;
	experience: number;
	globalProgress: number;
}

const UserExperience: React.FC<Props> = ({ level, experience, globalProgress }) => {
	const navigate = useNavigate();

	return (
		<motion.div
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			className={style.container}
			onClick={() => navigate("/tasks")}
		>
			<h1 id={style.level}>
				<span className={style.level}>
					<span className={style.levelText}>Level </span>
					{level}
				</span>
				<span className={style.nextLevel} id={style.nextLevel}>
					<span className={style.levelText}>Level </span>
					{level + 1}
				</span>
			</h1>
			<ProgressBar globalProgress={globalProgress} />
			<h1 id={style.experience}>{experience} meters</h1>
		</motion.div>
	);
};

export default UserExperience;
