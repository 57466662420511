import style from "./style/userProfile.module.css";

import { Avatar } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";

interface Props {
	name: string;
	department: string;
}

const UserProfile: React.FC<Props> = ({ name, department }) => {
	const navigate = useNavigate();

	return (
		<motion.div
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			className={style.container}
			onClick={() => navigate("/challenges")}
		>
			<div className={style.child} id={style.profilePicture}>
				<Avatar name={name} size={"lg"} />
			</div>
			<div className={style.child} id={style.userInformations}>
				<h1 id={style.name}>{name}</h1>
				<h1 id={style.department}>{department}</h1>
			</div>
		</motion.div>
	);
};

export default UserProfile;
