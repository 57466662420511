import { TasksPageTasklists } from "types/FetchedData";

interface Props {
	globalTaskList: TasksPageTasklists["globalTaskList"];
	setFilteredTaskList: React.Dispatch<React.SetStateAction<TasksPageTasklists["globalTaskList"]>>;
	filter: string;
}

export const filterLogic = ({ globalTaskList, setFilteredTaskList, filter }: Props) => {
	if (filter === "all") setFilteredTaskList(globalTaskList);
	else {
		const result = globalTaskList.filter((obj) => {
			return obj.typeTask === filter;
		});
		setFilteredTaskList(result);
	}
};
