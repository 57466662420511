import { TasksPageTasklists } from "types/FetchedData";

interface Props {
	globalTaskList: TasksPageTasklists["globalTaskList"];
}

export const getFilters = ({ globalTaskList }: Props) => {
	const allFilters = globalTaskList.map((a) => a.typeTask);

	const filters = allFilters.filter(function (item, pos) {
		return allFilters.indexOf(item) === pos;
	});

	return filters;
};
