import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HomepageData, Leaderboard, TasksPageTasklists, Challenge } from "types/FetchedData";

import { ClaimTaskQuery } from "./types/queryTypes";

import { AppUrl } from "config/environmentVariables";

import { RootState } from "stores/store";

export const gamificationApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: AppUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).user.token;
			//const email = (getState() as RootState).user.email;

			headers.set("Origin", "http://localhost:3000");
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			if (!headers.has("Content-Type")) {
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getHomepage: builder.query<HomepageData, null>({
			query: () => ({
				url: "api/homepage",
				method: "GET",
			}),
		}),
		getVersion: builder.query<string, null>({
			query: () => ({
				url: "api/homepage/version",
				method: "GET",
			}),
		}),
		getLeaderboard: builder.query<Leaderboard, null>({
			query: () => ({
				url: "api/leaderboard",
				method: "GET",
			}),
		}),
		getChallengeList: builder.query<Challenge[], null>({
			query: () => ({
				url: "api/challenge",
				method: "GET",
			}),
		}),
		getTaskList: builder.query<TasksPageTasklists, string>({
			query: (userFunction) => ({
				url: `api/task?Department=${encodeURIComponent(userFunction)}`,
				method: "GET",
			}),
		}),
		claimTask: builder.mutation<string, ClaimTaskQuery>({
			query: ({ userID, taskID, additionalComment, additionalFile }) => {
				const formData = new FormData();
				formData.append("userID", userID);
				formData.append("taskID", taskID);
				if (additionalComment) {
					formData.append("additionalComment", additionalComment);
				}
				if (additionalFile) {
					formData.append("additionalFile", additionalFile);
				}

				return {
					url: "api/claimTask",
					method: "POST",
					body: formData,
					headers: new Headers({}),
				};
			},
		}),
		report: builder.mutation<string, string>({
			query: (message) => ({
				url: "api/homepage/report",
				method: "POST",
				headers: {
					message: message,
				},
			}),
		}),
	}),
});

export const {
	useGetHomepageQuery,
	useGetLeaderboardQuery,
	useGetTaskListQuery,
	useClaimTaskMutation,
	useGetChallengeListQuery,
	useGetVersionQuery,
	useReportMutation,
} = gamificationApi;
