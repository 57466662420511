import { motion } from "framer-motion";

import { MdHelpCenter } from "react-icons/md";

import style from "./style/helpButton.module.css";

const HelpButton = () => {
	return (
		<motion.button
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			className={style.container}
			onClick={() =>
				window.open("https://ainos.sharepoint.com/sites/Intranet/SitePages/Guide-Utilisateur.aspx", "_blank")
			}
		>
			<MdHelpCenter size={40} />
		</motion.button>
	);
};

export default HelpButton;
