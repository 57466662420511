const gradients: { [key: string]: string } = {
	"night-background": "linear-gradient(to bottom, #020111 10%,#3a3a52 100%)",
	"morning-background": "linear-gradient(to bottom, #40405c 0%,#6f71aa 80%,#8a76ab 100%)",
	"second-morning-background": "linear-gradient(to bottom, #82addb 0%,#ebb2b1 100%)",
	"third-morning-background": "linear-gradient(to bottom, #b7eaff 0%,#94dfff 100%)",
	"afternoon-background": "linear-gradient(to bottom, #9be2fe 0%,#67d1fb 100%)",
	"second-afternoon-background": "linear-gradient(to bottom, #57c1eb 0%,#246fa8 100%)",
	"third-afternoon-background": "linear-gradient(to bottom, #2473ab 0%,#1e528e 70%,#5b7983 100%)",
	"pre-night-background": "linear-gradient(to bottom, #163C52 0%,#4F4F47 30%,#C5752D 60%,#B7490F 80%, #2F1107 100%)",
};

export { gradients };
