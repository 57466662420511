import { BsCalendarEvent } from "react-icons/bs";

import GenericModal from "components/GenericModal";

import style from "../style/events.module.css";

import EventTable from "./EventTable";

import { HomepageData } from "types/FetchedData";

interface Props {
	onClose: () => void;
	isOpen: boolean;
	eventList: HomepageData["events"];
}

const EventsModal: React.FC<Props> = ({ onClose, eventList, isOpen }) => {
	return (
		<GenericModal
			size={"4xl"}
			onClose={onClose}
			isOpen={isOpen}
			header={
				<div className={style.header}>
					<BsCalendarEvent size={30} />
					<h1 className={style.title}>Events</h1>
				</div>
			}
			body={<EventTable eventList={eventList} />}
		/>
	);
};

export default EventsModal;
