import FadeIn from "animations/FadeIn";
import { HomepageData } from "types/FetchedData";
import { TasksPageTasklists } from "types/FetchedData";
import { gradients } from "utils/backgroundGradients";
import UserProfile from "features/global/userProfile";
import HomeButton from "features/global/homeButton";
import TaskList from "features/tasks/taskList/TaskList";
import style from "./style/TasksPage.module.css";
interface Props {
	user: HomepageData["user"];
	taskList: TasksPageTasklists;
	background: string;
}

const TaskPageContent: React.FC<Props> = ({ user, taskList, background }) => {
	return (
		<div
			className={style.ProfilePage}
			style={{
				background: gradients[`${background}`],
			}}
		>
			<FadeIn delay={0.3}>
				<UserProfile name={user.name} department={user.function} />
			</FadeIn>
			<FadeIn delay={0.4} className={style.TaskList}>
				<TaskList taskList={taskList} user={user} />
			</FadeIn>
			<FadeIn delay={0.5}>
				<HomeButton />
			</FadeIn>
		</div>
	);
};

export default TaskPageContent;
