import { Routes, Route } from "react-router-dom";

import ConnectionPage from "../pages/ConnectionPage";

import IsAuthenticated from "./IsAuthenticated";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const Router = () => {
	return (
		<>
			<AuthenticatedTemplate>
				<IsAuthenticated />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Routes>
					<Route path="*" element={<ConnectionPage />} />
				</Routes>
			</UnauthenticatedTemplate>
		</>
	);
};

export default Router;
