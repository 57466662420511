import { SimpleGrid } from "@chakra-ui/react";

import TaskComponent from "../Task";

import { HomepageData } from "types/FetchedData";

import style from "./style/taskListContent.module.css";
import { Task } from "types/NestedInterfaces";

interface Props {
	user: HomepageData["user"];
	taskList: Task[];
	margin?: number;
}

const TaskListContent: React.FC<Props> = ({ user, taskList, margin }) => {
	return (
		<div className={style.container} style={{ marginTop: margin }}>
			<SimpleGrid minChildWidth="120px" spacing="20px">
				{taskList?.map((data, index) => {
					return <TaskComponent user={user} key={index} task={data} />;
				})}
			</SimpleGrid>
		</div>
	);
};

export default TaskListContent;
