import LogoutButton from "features/global/logoutButton";
import AinosLogo from "assets/images/logo_ainos.png";
import style from "./style/HomePage.module.css";
import FadeIn from "animations/FadeIn";
import { Center } from "@chakra-ui/react";

interface Props {
	error: string;
}

const HomePageErrorContent: React.FC<Props> = ({ error }) => {
	return (
		<Center className={style.background}>
			<div className={style.container} style={{ color: "red" }}>
				<img className={style.ainosLogo} src={AinosLogo} alt="logo Ainos" />
				<p>
					<b>Error :</b>
				</p>
				{error ? error : "An error occured during request."}
			</div>
			<FadeIn delay={1}>
				<LogoutButton />
			</FadeIn>
		</Center>
	);
};

export default HomePageErrorContent;
