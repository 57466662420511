import { Challenge, HomepageData } from "types/FetchedData";

import style from "./style/challengeListContent.module.css";
import ChallengeComponent from "../Challenge/Challenge";

import { SimpleGrid } from "@chakra-ui/react";

interface Props {
	user: HomepageData["user"];
	challengeList: Challenge[];
	margin?: number;
}

const ChallengeListContent: React.FC<Props> = ({ user, challengeList, margin }) => {
	var sortedChallenges = [...challengeList]?.sort((a, b) => {
		const numA = parseInt(a.name.match(/\d+/)?.[0] || "0", 10);
		const numB = parseInt(b.name.match(/\d+/)?.[0] || "0", 10);
		return numA - numB;
	});
	return (
		<div className={style.container} style={{ marginTop: margin }}>
			<SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(400px, 1fr))">
				{sortedChallenges?.map((data, index) => {
					return <ChallengeComponent user={user} key={index} challenge={data} />;
				})}
			</SimpleGrid>
		</div>
	);
};

export default ChallengeListContent;
