import useFetchHomeData from "hooks/api/useFetchHomeData";
import useFetchLeaderboard from "hooks/api/useFetchLeaderboard";
import useHourBackground from "hooks/home/useHourBackground";

import Loading from "features/global/loading";

import LeaderboardContent from "./LeaderboardContent";

const Leaderboard = () => {
	const homepageData = useFetchHomeData();
	const leaderboard = useFetchLeaderboard();
	const background = useHourBackground();

	return (
		<div>
			{leaderboard.data && homepageData.data && background ? (
				<LeaderboardContent
					user={homepageData.data.user}
					podium={leaderboard.data.podium}
					position={leaderboard.data.position}
					background={background}
				/>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default Leaderboard;
