import { useState } from "react";

import {
	Portal,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	Button,
	useDisclosure,
	HStack,
	Stack,
	Center,
} from "@chakra-ui/react";

import { difficultyColor } from "./utils/difficultyColor.constants";

import ClaimTask from "features/tasks/claimTask";

import { HomepageData } from "types/FetchedData";

import { MdDownhillSkiing } from "react-icons/md";
import { BsBookmarkCheckFill, BsBookmarkXFill } from "react-icons/bs";
import { Periodicity, Task } from "types/NestedInterfaces";
import { FcAlarmClock } from "react-icons/fc";

interface Props {
	user: HomepageData["user"];
	task: Task;
}

const TaskPopover: React.FC<Props> = ({ user, task }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [clickedTask, setClickedTask] = useState<Task>();
	return (
		<>
			<Portal>
				<PopoverContent style={{ textAlign: "center" }}>
					<PopoverArrow />
					<PopoverHeader>{task.name}</PopoverHeader>
					{task.description !== null ? <PopoverHeader>{task.description}</PopoverHeader> : <></>}
					<PopoverCloseButton />
					<PopoverBody
						alignItems="center"
						style={{
							color: difficultyColor[`${task.difficulty}`],
							paddingLeft: 30,
							paddingRight: 30,
						}}
					>
						<MdDownhillSkiing size={25} style={{ position: "fixed" }} />
						<span> {task.difficulty} track</span>
						<span> | </span>
						<span> +{task.reward} meters</span>
					</PopoverBody>
					{task.maxClaims > 1 ? (
						<div>
							<hr />
							<PopoverBody>
								<Stack>
									<HStack alignSelf="center">
										{Array.from({ length: task.timesClaimed }, (_, i) => (
											<BsBookmarkCheckFill key={i} size={"3vh"} color="green" />
										))}
										{Array.from(
											{
												length: task.maxClaims - task.timesClaimed,
											},
											(_, i) => (
												<BsBookmarkXFill key={i} size={"3vh"} color="grey" />
											)
										)}
									</HStack>
								</Stack>
							</PopoverBody>
						</div>
					) : (
						<></>
					)}

					{task.periodicity !== Periodicity.Null && task.periodicity !== Periodicity.Casual ? (
						<div>
							<hr />
							<PopoverBody>
								<Stack>
									<HStack alignSelf="center">
										<FcAlarmClock size={"3vh"} />
										<Center>Task periodicity : {task.periodicity}</Center>
									</HStack>
								</Stack>
							</PopoverBody>
						</div>
					) : (
						<></>
					)}

					<PopoverFooter>
						<Button
							colorScheme="green"
							disabled={!task.isClaimable}
							onClick={() => {
								onOpen();
								setClickedTask(task!);
							}}
						>
							{task.isClaimable && task.timesClaimed === 0 && <span>Claim</span>}

							{task.timesClaimed > 0 && !task.isValid && <span>Waiting for validation ⏳</span>}

							{task.timesClaimed > 0 && task.timesClaimed < task.maxClaims && task.isValid && (
								<span>Claim again 🔁</span>
							)}

							{task.timesClaimed === task.maxClaims && task.isValid && (
								<span>Task claimed and validated ✅</span>
							)}
						</Button>
					</PopoverFooter>
				</PopoverContent>
			</Portal>
			<ClaimTask isOpen={isOpen} onClose={onClose} task={clickedTask!} user={user} />
		</>
	);
};

export default TaskPopover;
