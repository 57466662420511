import style from "./style/cloud.module.css";

const Clouds = () => {
	function getRandomHeight() {
		return Math.floor(Math.random() * 40) + 10;
	}

	function getRandomSpeed() {
		return Math.floor(Math.random() * 15) + 15;
	}

	var heightFirst = getRandomHeight();
	var heightSecond = getRandomHeight();
	while (Math.abs(heightFirst - heightSecond) < 20) {
		heightSecond = getRandomHeight();
	}
	return (
		<div>
			<div className={style.center} style={{ top: heightFirst + "%" }}>
				<div id={style.cloud} style={{ animationDuration: getRandomSpeed() + "s" }} />
			</div>

			<div className={style.center} style={{ top: heightSecond + "%" }}>
				<div id={style.cloud} style={{ animationDuration: getRandomSpeed() + "s" }} />
			</div>
		</div>
	);
};

export default Clouds;
