import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from "@chakra-ui/react";

interface Props {
    size?: string;
    onClose: () => void;
    isOpen: boolean;
    header: JSX.Element;
    body: JSX.Element;
    button?: JSX.Element;
}

const GenericModal: React.FC<Props> = ({ size = "lg", onClose, isOpen, header, body, button }) => {
    return (
        <Modal size={size} onClose={onClose} isOpen={isOpen} isCentered blockScrollOnMount={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {header}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    {button}
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default GenericModal;