import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
	colors: {
		userProgress: {
			500: "#27ae60",
		},
		error: {
			500: "#c0392b",
		},
	},
});
