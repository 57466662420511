import Loading from "features/global/loading/Loading";
import useFetchProfile from "hooks/api/useFetchProfile";
import useFetchHomeData from "hooks/api/useFetchHomeData";
import useHourBackground from "hooks/home/useHourBackground";
import TasksPageContent from "./TasksPageContent";

const TasksPage = () => {
	const homepageData = useFetchHomeData();
	const background = useHourBackground();
	var userTeam = "undefined";

	if (homepageData.data?.user.teamId) {
		userTeam = homepageData.data?.user.teamId;
	}

	const taskList = useFetchProfile(userTeam);
	return (
		<div
			style={{
				height: "100%",
			}}
		>
			{homepageData.isSuccess && homepageData.data && background && taskList.data && taskList.isSuccess ? (
				<TasksPageContent user={homepageData.data.user} taskList={taskList.data} background={background} />
			) : (
				<Loading />
			)}
		</div>
	);
};

export default TasksPage;
