import { createRoot } from "react-dom/client";

// Msal
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../msalConfig";

const container = document.getElementById("root")!;
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

export { root, msalInstance };
