import { Leaderboard, HomepageData } from "types/FetchedData";

import FadeIn from "animations/FadeIn";

import UserExperience from "features/global/userExperience";
import LogoutButton from "features/global/logoutButton/LogoutButton";
import HomeButton from "features/global/homeButton";

import LeaderboardPodium from "features/leaderboard/LeaderboardPodium";

import { gradients } from "utils/backgroundGradients";

import style from "./style/Leaderboard.module.css";

interface Props {
	user: HomepageData["user"];
	podium: Leaderboard["podium"];
	position: Leaderboard["position"];
	background: string;
}

const LeaderboardContent: React.FC<Props> = ({ user, podium, position, background }) => {
	return (
		<div className={style.Leaderboard} style={{ background: gradients[`${background}`] }}>
			<FadeIn delay={0.3}>
				<UserExperience experience={user.experience} level={user.level} globalProgress={user.globalProgress} />
			</FadeIn>
			<FadeIn delay={0.4} className={style.LeaderboardPodium}>
				<LeaderboardPodium podium={podium} position={position} />
			</FadeIn>
			<FadeIn delay={0.5}>
				<LogoutButton />
			</FadeIn>
			<FadeIn delay={0.6}>
				<HomeButton />
			</FadeIn>
		</div>
	);
};

export default LeaderboardContent;
