import GenericModal from "components/GenericModal";

import { useEffect, useState } from "react";

import style from "./style/modal.module.css";

import { Button, Textarea } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import { MdTaskAlt } from "react-icons/md";

import { HomepageData } from "types/FetchedData";
import { useClaimTaskMutation } from "stores/services/gamificationApi";

import { errorToast, errorClaimToast } from "./utils/claimToasts";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Task } from "types/NestedInterfaces";
import { ExtFile, FileCard, FileInputButton } from "@files-ui/react";

interface Props {
	onClose: () => void;
	isOpen: boolean;
	user: HomepageData["user"];
	task: Task;
}

const ClaimTask: React.FC<Props> = ({ user, onClose, isOpen, task }) => {
	const toast = useToast();
	const [claimTask, result] = useClaimTaskMutation();
	const [comment, setComment] = useState<string | undefined>(undefined);

	// File for FileInputButton (Type ExtFile)
	const [files, setFiles] = useState<ExtFile[]>([]);

	// File for send to API (Type File).
	const [file, setFile] = useState<File | undefined>(undefined);

	const updateFiles = (incommingFiles: ExtFile[]) => {
		if (incommingFiles[0].errors === undefined) {
			setFile(incommingFiles[0].file);
			setFiles(incommingFiles);
		}
	};

	const removeFile = (id: number | string | undefined) => {
		setFiles(files.filter((x) => x.id !== id));
		setFile(undefined);
	};

	function handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		setComment(e.target.value);
	}

	useEffect(() => {
		const summonToast = () => {
			if (result.status === "fulfilled") {
				window.location.reload();
			} else if (result.isError === true) {
				var statuscode = (result.error as FetchBaseQueryError).status;
				if (statuscode === 409) {
					toast(errorClaimToast);
				} else {
					toast(errorToast);
				}
			}
		};
		summonToast();
	}, [result, toast]);

	return (
		<GenericModal
			onClose={onClose}
			isOpen={isOpen}
			header={
				<div className={style.header}>
					<MdTaskAlt size={30} />
					<h1 className={style.title}>Confirmation</h1>
				</div>
			}
			body={
				<div>
					<h1>Are you sure you finished this task ?</h1>
					<h1>This action will send a ping to get a verification.</h1>
					<h2>You will only receive your experience once confirmed.</h2>
					<br />
					<hr />
					<br />
					<h1>Anything you would like to share :</h1>
					<span>
						<Textarea id="claimComment" onChange={handleDescriptionChange} />
					</span>
					<span style={{ margin: "1rem" }}>
						{files.length > 0 ? (
							<span
								style={{
									display: "flex",

									justifyContent: "center",
									flexWrap: "wrap",
									gap: "5px",
									minWidth: "50%",
								}}
							>
								{files.map((file: ExtFile) => (
									<FileCard key={file.id} {...file} onDelete={removeFile} preview />
								))}
							</span>
						) : (
							<div style={{ textAlign: "center" }}>
								<FileInputButton
									accept="image/png, image/jpeg, application/pdf"
									maxFiles={1}
									onChange={updateFiles}
									value={files}
									color="black"
								>
									ATTACH
								</FileInputButton>
							</div>
						)}
					</span>
					<hr />
				</div>
			}
			button={
				<div>
					<Button
						colorScheme="green"
						mr={3}
						onClick={() => {
							claimTask({
								userID: user.userID,
								taskID: task.id,
								additionalFile: file,
								additionalComment: comment,
							});
							onClose();
						}}
					>
						Claim
					</Button>
				</div>
			}
		/>
	);
};

export default ClaimTask;
