import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface User {
	token: string;
	email: string;
}

const initialState: User = {
	token: "",
	email: "",
};

export const counterSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state: any, action: PayloadAction<User>) => {
			state.token = action.payload.token;
			state.email = action.payload.email;
		},
	},
});

export const { login } = counterSlice.actions;
export const selectToken = (state: RootState) => state.user.token;
export const selectIsAuthenticating = (state: RootState) => !state.user.token;

export default counterSlice.reducer;
