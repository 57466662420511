import { Button } from "@chakra-ui/react";

import style from "./style/logoutButton.module.css";

import { CgLogOut } from "react-icons/cg";

import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import msalLogout from "./utils/msalLogout";

import { motion } from "framer-motion";

const LogoutButton = () => {
	const { instance } = useMsal();
	const navigate = useNavigate();

	return (
		<motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className={style.container}>
			<Button
				leftIcon={<CgLogOut size={23} />}
				colorScheme="whiteAlpha"
				variant="solid"
				size={"lg"}
				style={{ color: "#000" }}
				onClick={() => {
					msalLogout({ instance, navigate });
				}}
				className={style.button}
			>
				<p className={style.buttonName}>Disconnect</p>
			</Button>
		</motion.div>
	);
};

export default LogoutButton;
