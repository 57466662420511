import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";

import { HomepageData } from "types/FetchedData";

interface Props {
	eventList: HomepageData["events"];
}

const EventTable: React.FC<Props> = ({ eventList }) => {
	return (
		<TableContainer>
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th>Title</Th>
						<Th>Description</Th>
						<Th isNumeric>Date</Th>
					</Tr>
				</Thead>
				{eventList.map((data, index) => (
					<Tbody key={index}>
						<Tr>
							<Td>{data.name}</Td>
							<Td>{data.description}</Td>
							<Td isNumeric>
								{new Date(data.date).toLocaleString([], {
									year: "numeric",
									month: "numeric",
									day: "numeric",
									hour: "2-digit",
									minute: "2-digit",
								})}
							</Td>
						</Tr>
					</Tbody>
				))}
			</Table>
		</TableContainer>
	);
};

export default EventTable;
