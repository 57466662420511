import { motion } from "framer-motion";

import { IoMdBug } from "react-icons/io";

import style from "./style/bugButton.module.css";

interface BugButtonProps {
	onOpenModal: () => void;
}

const BugButton: React.FC<BugButtonProps> = ({ onOpenModal }) => {
	return (
		<motion.button
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			className={style.container}
			onClick={onOpenModal}
		>
			<IoMdBug size={40} />
		</motion.button>
	);
};

export default BugButton;
