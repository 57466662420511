import { motion } from "framer-motion";

import { FaMountain } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import style from "./style/homeButton.module.css";

const HomeButton = () => {
	const navigate = useNavigate();

	return (
		<motion.button
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			className={style.container}
			onClick={() => navigate("/home")}
		>
			<FaMountain size={40} />
		</motion.button>
	);
};

export default HomeButton;
